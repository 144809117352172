<template>
	<span v-if="item[column.value]">
        {{mwtimeutils.formatAsDateTimeString(item[column.value])}}
    </span>
</template>


<script>
    export default {
        name: "DateCell", 
        props: ["item", "column"]
    }
</script>